import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2ddcf3c7"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "outBox"
};
const _hoisted_2 = {
  class: "content-box"
};
const _hoisted_3 = {
  class: "top-bar-box"
};
const _hoisted_4 = {
  class: "left"
};
const _hoisted_5 = {
  class: "store-status-lable"
};
const _hoisted_6 = {
  class: "checkListBox"
};
const _hoisted_7 = {
  style: {
    "padding": "8px 12px",
    "max-height": "500px",
    "overflow-y": "auto"
  }
};
const _hoisted_8 = ["value"];
const _hoisted_9 = {
  class: "store-status-lable"
};
const _hoisted_10 = {
  class: "checkListBox"
};
const _hoisted_11 = {
  style: {
    "padding": "8px 12px",
    "max-height": "500px",
    "overflow-y": "auto"
  }
};
const _hoisted_12 = ["value"];
const _hoisted_13 = {
  class: "store-status-lable"
};
const _hoisted_14 = {
  class: "store-status-lable"
};
const _hoisted_15 = {
  class: "right"
};
const _hoisted_16 = {
  class: "upload-box"
};
const _hoisted_17 = {
  class: "upload-box-title"
};
const _hoisted_18 = {
  class: "upload-box-title-text"
};
const _hoisted_19 = {
  style: {
    "display": "flex",
    "flex-direction": "column"
  }
};
const _hoisted_20 = {
  class: "upload-box-dashed"
};
const _hoisted_21 = {
  class: "upload-icon-group-box"
};
const _hoisted_22 = {
  class: "el-upload__text"
};
const _hoisted_23 = {
  style: {
    "font-size": "12px"
  }
};
const _hoisted_24 = {
  class: "table-box"
};
const _hoisted_25 = ["onClick"];
const _hoisted_26 = {
  key: 0,
  style: {
    "color": "#0763db"
  }
};
const _hoisted_27 = {
  class: "store-activity-box",
  style: {
    "display": "flex"
  }
};
const _hoisted_28 = {
  style: {
    "margin-left": "8px"
  }
};
const _hoisted_29 = {
  class: "bottom-pagination-box"
};
const _hoisted_30 = {
  style: {
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_31 = {
  class: "ex-dialog-title",
  style: {
    "width": "auto"
  }
};
const _hoisted_32 = {
  style: {
    "display": "flex",
    "flex-direction": "column",
    "overflow-y": "auto"
  }
};
const _hoisted_33 = {
  style: {
    "display": "flex",
    "justify-content": "end",
    "padding": "10px 0px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_down_circle_outlined = _resolveComponent("down-circle-outlined");
  const _component_a_checkbox = _resolveComponent("a-checkbox");
  const _component_a_divider = _resolveComponent("a-divider");
  const _component_a_select = _resolveComponent("a-select");
  const _component_a_cascader = _resolveComponent("a-cascader");
  const _component_a_select_option = _resolveComponent("a-select-option");
  const _component_a_button = _resolveComponent("a-button");
  const _component_svg_icon = _resolveComponent("svg-icon");
  const _component_el_upload = _resolveComponent("el-upload");
  const _component_el_popover = _resolveComponent("el-popover");
  const _component_a_input_search = _resolveComponent("a-input-search");
  const _component_vxe_column = _resolveComponent("vxe-column");
  const _component_a_tag = _resolveComponent("a-tag");
  const _component_a_tooltip = _resolveComponent("a-tooltip");
  const _component_a_menu_item = _resolveComponent("a-menu-item");
  const _component_a_menu = _resolveComponent("a-menu");
  const _component_a_dropdown = _resolveComponent("a-dropdown");
  const _component_vxe_table = _resolveComponent("vxe-table");
  const _component_vxe_pager = _resolveComponent("vxe-pager");
  const _component_a_input = _resolveComponent("a-input");
  const _component_a_form_item = _resolveComponent("a-form-item");
  const _component_a_form = _resolveComponent("a-form");
  const _component_a_modal = _resolveComponent("a-modal");
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_ex_dialog = _resolveComponent("ex-dialog");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_a_select, {
    value: _ctx.storeData.selectedStoreTypes,
    "onUpdate:value": _cache[0] || (_cache[0] = $event => _ctx.storeData.selectedStoreTypes = $event),
    placeholder: _ctx.$t('storeManage.selectStoreType'),
    size: "middle",
    mode: "multiple",
    "allow-clear": "",
    style: {
      "width": "200px"
    },
    "max-tag-count": 1,
    dropdownMatchSelectWidth: false,
    showArrow: true,
    "option-label-prop": "children",
    "max-tag-text-length": 8
  }, {
    suffixIcon: _withCtx(() => [_createVNode(_component_down_circle_outlined, {
      class: "ant-select-suffix",
      style: {
        "color": "#818181"
      }
    })]),
    dropdownRender: _withCtx(() => [_createElementVNode("div", _hoisted_6, [_createVNode(_component_a_checkbox, {
      checked: _ctx.storeData.storePageRO.checkAll,
      onChange: _ctx.onCheckAllChange,
      style: {
        "padding": "8px 12px"
      }
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('storeManage.selectAll')), 1)]),
      _: 1
    }, 8, ["checked", "onChange"]), _createVNode(_component_a_divider, {
      style: {
        "margin": "0"
      }
    }), _createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.storeData.storeTypeData, item => {
      return _openBlock(), _createElementBlock("p", {
        key: item.storeTypeId,
        value: item.storeTypeId
      }, [_createVNode(_component_a_checkbox, {
        checked: _ctx.storeData.storePageRO.storeTypeIds.includes(item.storeTypeId),
        onChange: $event => _ctx.onCheckboxChange(item.storeTypeId, $event)
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(item.storeTypeNameZh), 1)]),
        _: 2
      }, 1032, ["checked", "onChange"])], 8, _hoisted_8);
    }), 128))])])]),
    _: 1
  }, 8, ["value", "placeholder"])]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_a_select, {
    value: _ctx.storeData.selectedActivityIds,
    "onUpdate:value": _cache[1] || (_cache[1] = $event => _ctx.storeData.selectedActivityIds = $event),
    placeholder: _ctx.$t('storeManage.selectActivityName'),
    size: "middle",
    mode: "multiple",
    "allow-clear": "",
    style: {
      "width": "200px"
    },
    "max-tag-count": 1,
    dropdownMatchSelectWidth: false,
    showArrow: true,
    "option-label-prop": "children",
    "max-tag-text-length": 8
  }, {
    suffixIcon: _withCtx(() => [_createVNode(_component_down_circle_outlined, {
      class: "ant-select-suffix",
      style: {
        "color": "#818181"
      }
    })]),
    dropdownRender: _withCtx(() => [_createElementVNode("div", _hoisted_10, [_createVNode(_component_a_checkbox, {
      checked: _ctx.storeData.storePageRO.activityCheckAll,
      onChange: _ctx.onActivityCheckAllChange,
      style: {
        "padding": "8px 12px"
      }
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('storeManage.selectAll')), 1)]),
      _: 1
    }, 8, ["checked", "onChange"]), _createVNode(_component_a_divider, {
      style: {
        "margin": "0"
      }
    }), _createElementVNode("div", _hoisted_11, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activityData, item => {
      return _openBlock(), _createElementBlock("p", {
        key: item.activityId,
        value: item.activityId
      }, [_createVNode(_component_a_checkbox, {
        checked: _ctx.storeData.storePageRO.activityIds.includes(item.activityId),
        onChange: $event => _ctx.onActivityCheckboxChange(item.activityId, $event)
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(item.activityName), 1)]),
        _: 2
      }, 1032, ["checked", "onChange"])], 8, _hoisted_12);
    }), 128))])])]),
    _: 1
  }, 8, ["value", "placeholder"])]), _createElementVNode("div", _hoisted_13, [_createVNode(_component_a_cascader, {
    style: {
      "width": "150px"
    },
    value: _ctx.storeData.storePageRO.provinceCity,
    "onUpdate:value": _cache[2] || (_cache[2] = $event => _ctx.storeData.storePageRO.provinceCity = $event),
    options: _ctx.storeData.provinceDictData,
    placeholder: _ctx.$t('storeManage.selectCity'),
    changeOnSelect: true,
    "expand-trigger": "hover",
    onChange: _ctx.cityChange
  }, {
    suffixIcon: _withCtx(() => [_createVNode(_component_down_circle_outlined, {
      class: "ant-select-suffix",
      style: {
        "color": "#818181"
      }
    })]),
    _: 1
  }, 8, ["value", "options", "placeholder", "onChange"])]), _createElementVNode("div", _hoisted_14, [_createVNode(_component_a_select, {
    class: "query-field",
    filterable: "",
    allowClear: "",
    value: _ctx.storeData.storePageRO.format,
    "onUpdate:value": _cache[3] || (_cache[3] = $event => _ctx.storeData.storePageRO.format = $event),
    placeholder: _ctx.$t('storeManage.selectFormat'),
    size: "medium",
    onChange: _ctx.formatChange,
    style: {
      "width": "130px"
    }
  }, {
    suffixIcon: _withCtx(() => [_createVNode(_component_down_circle_outlined, {
      class: "ant-select-suffix",
      style: {
        "color": "#818181"
      }
    })]),
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.storeData.formatDictData, item => {
      return _openBlock(), _createBlock(_component_a_select_option, {
        key: item.value,
        value: item.value
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(item.label), 1)]),
        _: 2
      }, 1032, ["value"]);
    }), 128))]),
    _: 1
  }, 8, ["value", "placeholder", "onChange"])]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.storeData.storeStatusData, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "store-status-lable storeStatusBox",
      style: _normalizeStyle({
        'background-color': _ctx.storeData.storeStatusColorData[index]
      })
    }, _toDisplayString(_ctx.locale == "zh" ? item.storeStatusNameZh : item.storeStatusNameEn), 5);
  }), 128))]), _createElementVNode("div", _hoisted_15, [_createVNode(_component_el_popover, {
    visible: _ctx.uploadStoreLocationPopoverVisible,
    placement: "bottom",
    trigger: "click",
    width: "auto",
    teleported: false,
    "popper-class": "upload-box-popper"
  }, {
    reference: _withCtx(() => [_createVNode(_component_a_button, {
      class: "sts-button default",
      color: "black",
      onClick: _ctx.openUploadStoreLocationPopover,
      style: {
        "margin-right": "15px",
        "width": "130px",
        "visibility": "hidden"
      }
    }, {
      default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$t('storeManage.uploadStorePoints')), 1)]),
      _: 1
    }, 8, ["onClick"])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, [_createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('storeManage.pointDataUpload')), 1), _createVNode(_component_svg_icon, {
      iconName: "close",
      iconColor: 'black',
      iconSize: "15px",
      style: {
        "cursor": "pointer",
        "padding": "3px"
      },
      onClick: _cache[4] || (_cache[4] = $event => _ctx.uploadStoreLocationPopoverVisible = false)
    })]), _createVNode(_component_el_upload, {
      "on-exceed": _ctx.handleStoreLocationExceed,
      class: "upload-pane",
      drag: "",
      ref: "uploadStoreLocation",
      action: _ctx.uploadStoreLocationData.signatureData.host,
      "before-upload": _ctx.handleStoreLocationBeforeUpload,
      "on-success": _ctx.handleStoreLocationOnSuccess,
      data: _ctx.uploadStoreLocationData.data,
      limit: 1,
      "file-list": _ctx.uploadStoreLocationData.fileList
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_19, [_createVNode(_component_a_button, {
        class: "sts-button default",
        color: "#539ce0",
        onClick: _ctx.xuanze,
        style: {
          "width": "100px",
          "margin": "5px 0px"
        }
      }, {
        default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$t('storeManage.browse')), 1)]),
        _: 1
      }, 8, ["onClick"]), _createElementVNode("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, [_createVNode(_component_svg_icon, {
        iconName: "Excel",
        iconSize: "18px"
      }), _createVNode(_component_svg_icon, {
        iconName: "upload",
        iconSize: "18px"
      })]), _createElementVNode("div", _hoisted_22, [_createElementVNode("div", null, _toDisplayString(_ctx.$t('storeManage.uploadHint')), 1), _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t('storeManage.fileTypeHint')), 1)])])])]),
      _: 1
    }, 8, ["on-exceed", "action", "before-upload", "on-success", "data", "file-list"])])]),
    _: 1
  }, 8, ["visible"]), _createVNode(_component_a_input_search, {
    value: _ctx.storeData.storePageRO.searchKeyword,
    "onUpdate:value": _cache[5] || (_cache[5] = $event => _ctx.storeData.storePageRO.searchKeyword = $event),
    placeholder: _ctx.$t('storeManage.search'),
    style: {
      "width": "200px"
    },
    onSearch: _ctx.searchStoreClick
  }, null, 8, ["value", "placeholder", "onSearch"])])]), _createElementVNode("div", _hoisted_24, [_createVNode(_component_vxe_table, {
    border: false,
    stripe: "",
    size: "small",
    class: "column-sort",
    ref: "storeTable",
    height: "auto",
    loading: _ctx.storeLoading,
    "loading-config": {
      icon: 'vxe-icon-indicator roll',
      text: ' '
    },
    "row-config": {
      isHover: true
    },
    data: _ctx.storeData.data,
    "sort-config": {
      showIcon: false
    },
    onHeaderCellClick: _ctx.headerCellClickEvent,
    "row-class-name": _ctx.rowClassName,
    "empty-text": _ctx.$t('repairManage.noData')
  }, {
    default: _withCtx(() => [_createVNode(_component_vxe_column, {
      width: "350",
      field: "storeName",
      title: _ctx.$t('storeManage.storeName'),
      "show-overflow": "",
      sortable: ""
    }, {
      header: _withCtx(({
        column
      }) => [_createElementVNode("span", null, _toDisplayString(column.title), 1), column.order == 'asc' ? (_openBlock(), _createBlock(_component_svg_icon, {
        key: 0,
        iconName: "sortup",
        iconSize: "24px"
      })) : column.order == 'desc' ? (_openBlock(), _createBlock(_component_svg_icon, {
        key: 1,
        iconName: "sortdown",
        iconSize: "24px"
      })) : (_openBlock(), _createBlock(_component_svg_icon, {
        key: 2,
        iconName: "sort2",
        iconSize: "24px"
      }))]),
      default: _withCtx(({
        row
      }) => [_createElementVNode("span", {
        class: "hoverBorder",
        onClick: $event => _ctx.storeClickEvent(row),
        style: {
          "color": "#0763db",
          "cursor": "pointer"
        }
      }, _toDisplayString(row.storeName), 9, _hoisted_25)]),
      _: 1
    }, 8, ["title"]), _createVNode(_component_vxe_column, {
      field: "storeCode",
      width: "200",
      title: _ctx.$t('storeManage.storeCode'),
      sortable: ""
    }, {
      header: _withCtx(({
        column
      }) => [_createElementVNode("span", null, _toDisplayString(column.title), 1), column.order == 'asc' ? (_openBlock(), _createBlock(_component_svg_icon, {
        key: 0,
        iconName: "sortup",
        iconSize: "24px"
      })) : column.order == 'desc' ? (_openBlock(), _createBlock(_component_svg_icon, {
        key: 1,
        iconName: "sortdown",
        iconSize: "24px"
      })) : (_openBlock(), _createBlock(_component_svg_icon, {
        key: 2,
        iconName: "sort2",
        iconSize: "24px"
      }))]),
      _: 1
    }, 8, ["title"]), _createVNode(_component_vxe_column, {
      field: "storeTypeNameZh",
      width: "150",
      title: _ctx.$t('storeManage.storeType'),
      sortable: ""
    }, {
      header: _withCtx(({
        column
      }) => [_createElementVNode("span", null, _toDisplayString(column.title), 1), column.order == 'asc' ? (_openBlock(), _createBlock(_component_svg_icon, {
        key: 0,
        iconName: "sortup",
        iconSize: "24px"
      })) : column.order == 'desc' ? (_openBlock(), _createBlock(_component_svg_icon, {
        key: 1,
        iconName: "sortdown",
        iconSize: "24px"
      })) : (_openBlock(), _createBlock(_component_svg_icon, {
        key: 2,
        iconName: "sort2",
        iconSize: "24px"
      }))]),
      _: 1
    }, 8, ["title"]), _createVNode(_component_vxe_column, {
      field: "city",
      width: "100",
      title: _ctx.$t('storeManage.city'),
      sortable: ""
    }, {
      header: _withCtx(({
        column
      }) => [_createElementVNode("span", null, _toDisplayString(column.title), 1), column.order == 'asc' ? (_openBlock(), _createBlock(_component_svg_icon, {
        key: 0,
        iconName: "sortup",
        iconSize: "24px"
      })) : column.order == 'desc' ? (_openBlock(), _createBlock(_component_svg_icon, {
        key: 1,
        iconName: "sortdown",
        iconSize: "24px"
      })) : (_openBlock(), _createBlock(_component_svg_icon, {
        key: 2,
        iconName: "sort2",
        iconSize: "24px"
      }))]),
      _: 1
    }, 8, ["title"]), _createVNode(_component_vxe_column, {
      field: "activityName",
      title: _ctx.$t('storeManage.currentActivity'),
      "min-width": "300",
      "show-overflow": "",
      sortable: ""
    }, {
      header: _withCtx(({
        column
      }) => [_createElementVNode("span", null, _toDisplayString(column.title), 1), column.order == 'asc' ? (_openBlock(), _createBlock(_component_svg_icon, {
        key: 0,
        iconName: "sortup",
        iconSize: "24px"
      })) : column.order == 'desc' ? (_openBlock(), _createBlock(_component_svg_icon, {
        key: 1,
        iconName: "sortdown",
        iconSize: "24px"
      })) : (_openBlock(), _createBlock(_component_svg_icon, {
        key: 2,
        iconName: "sort2",
        iconSize: "24px"
      }))]),
      default: _withCtx(({
        row
      }) => [row.activityName == null ? (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(_ctx.$t('storeManage.noActivity')), 1)) : _createCommentVNode("", true), row.activityName != null ? (_openBlock(), _createBlock(_component_a_tooltip, {
        key: 1
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_27, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.activityNames, (name, index) => {
          return _openBlock(), _createBlock(_component_a_tag, {
            key: index
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(name), 1)]),
            _: 2
          }, 1024);
        }), 128))])]),
        title: _withCtx(() => [_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.activityNames, (name, index) => {
          return _openBlock(), _createElementBlock("p", {
            key: index
          }, _toDisplayString(name), 1);
        }), 128))])]),
        _: 2
      }, 1024)) : _createCommentVNode("", true)]),
      _: 1
    }, 8, ["title"]), _createVNode(_component_vxe_column, {
      field: "storeAddress",
      width: "350",
      title: _ctx.$t('storeManage.address'),
      "show-overflow": "",
      sortable: ""
    }, {
      header: _withCtx(({
        column
      }) => [_createElementVNode("span", null, _toDisplayString(column.title), 1), column.order == 'asc' ? (_openBlock(), _createBlock(_component_svg_icon, {
        key: 0,
        iconName: "sortup",
        iconSize: "24px"
      })) : column.order == 'desc' ? (_openBlock(), _createBlock(_component_svg_icon, {
        key: 1,
        iconName: "sortdown",
        iconSize: "24px"
      })) : (_openBlock(), _createBlock(_component_svg_icon, {
        key: 2,
        iconName: "sort2",
        iconSize: "24px"
      }))]),
      _: 1
    }, 8, ["title"]), _ctx.permission.havePermission('店铺管理', '点位管理') || _ctx.permission.havePermission('店铺管理', '店铺编辑') || _ctx.permission.havePermission('店铺管理', '店铺删除') ? (_openBlock(), _createBlock(_component_vxe_column, {
      key: 0,
      fixed: "right",
      title: _ctx.$t('storeManage.more'),
      width: "150",
      "min-width": "150"
    }, {
      header: _withCtx(({
        column
      }) => [_createElementVNode("span", _hoisted_28, _toDisplayString(column.title), 1)]),
      default: _withCtx(({
        row
      }) => [_createElementVNode("div", null, [_createVNode(_component_a_dropdown, null, {
        overlay: _withCtx(() => [_createVNode(_component_a_menu, null, {
          default: _withCtx(() => [_ctx.permission.havePermission('店铺管理', '点位管理') ? (_openBlock(), _createBlock(_component_a_menu_item, {
            key: 0
          }, {
            default: _withCtx(() => [_createVNode(_component_a_button, {
              style: {
                "width": "100px"
              },
              class: "sts-button default",
              onClick: $event => _ctx.goLocation(row)
            }, {
              default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$t('storeManage.pointManagement')), 1)]),
              _: 2
            }, 1032, ["onClick"])]),
            _: 2
          }, 1024)) : _createCommentVNode("", true), _ctx.permission.havePermission('店铺管理', '店铺编辑') ? (_openBlock(), _createBlock(_component_a_menu_item, {
            key: 1
          }, {
            default: _withCtx(() => [_createVNode(_component_a_button, {
              style: {
                "width": "100px"
              },
              class: "sts-button default",
              onClick: $event => _ctx.storeUpdateClick(row)
            }, {
              default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$t('storeManage.edit')), 1)]),
              _: 2
            }, 1032, ["onClick"])]),
            _: 2
          }, 1024)) : _createCommentVNode("", true), _ctx.permission.havePermission('店铺管理', '店铺删除') ? (_openBlock(), _createBlock(_component_a_menu_item, {
            key: 2
          }, {
            default: _withCtx(() => [_createVNode(_component_a_button, {
              style: {
                "width": "100px"
              },
              class: "sts-button default",
              onClick: $event => _ctx.storeDeleteClick(row)
            }, {
              default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$t('storeManage.delete')), 1)]),
              _: 2
            }, 1032, ["onClick"])]),
            _: 2
          }, 1024)) : _createCommentVNode("", true)]),
          _: 2
        }, 1024)]),
        default: _withCtx(() => [_createVNode(_component_a_button, {
          style: {
            "color": "#818181",
            "border": "1px solid #818181",
            "width": "25px",
            "height": "25px",
            "border-radius": "50%",
            "padding": "0"
          },
          type: "text"
        }, {
          default: _withCtx(() => [_createTextVNode(" ··· ")]),
          _: 1
        })]),
        _: 2
      }, 1024)])]),
      _: 1
    }, 8, ["title"])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["loading", "data", "onHeaderCellClick", "row-class-name", "empty-text"])]), _createElementVNode("div", _hoisted_29, [_createVNode(_component_vxe_pager, {
    class: "sts-pager",
    background: "",
    "page-size": _ctx.storeData.paginationData.pageSize,
    "onUpdate:pageSize": _cache[6] || (_cache[6] = $event => _ctx.storeData.paginationData.pageSize = $event),
    "current-page": _ctx.storeData.paginationData.currentPage,
    "onUpdate:currentPage": _cache[7] || (_cache[7] = $event => _ctx.storeData.paginationData.currentPage = $event),
    "pager-count": 5,
    total: _ctx.storeData.paginationData.totalSize,
    "page-sizes": _ctx.storeData.paginationData.pageSizes,
    layouts: ['PrevJump', 'JumpNumber', 'NextJump', 'Sizes', 'FullJump', 'Total'],
    onPageChange: _ctx.paginationCurrentChange
  }, null, 8, ["page-size", "current-page", "total", "page-sizes", "onPageChange"])])]), _createVNode(_component_a_modal, {
    open: _ctx.storeData.storeUpdateVisible,
    "onUpdate:open": _cache[17] || (_cache[17] = $event => _ctx.storeData.storeUpdateVisible = $event),
    closable: false,
    width: "500px",
    zIndex: 1001,
    centered: true,
    ref: "userDialog"
  }, {
    title: _withCtx(() => [_createVNode(_component_a_divider, {
      orientation: "left",
      orientationMargin: "0",
      class: "ex-dialog-title"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('storeManage.storeInfoEdit')), 1)]),
      _: 1
    })]),
    footer: _withCtx(() => [_createElementVNode("div", _hoisted_30, [_createVNode(_component_a_divider, {
      style: {
        "margin": "10px 30px 10px 0px",
        "min-width": "0px"
      }
    }), _createVNode(_component_a_button, {
      class: "sts-button default",
      key: "back",
      onClick: _ctx.cancelSaveStore
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('storeManage.cancel')), 1)]),
      _: 1
    }, 8, ["onClick"]), _createVNode(_component_a_button, {
      class: "sts-button green",
      key: "submit",
      type: "primary",
      onClick: _ctx.confirmSaveStore
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('storeManage.confirm')), 1)]),
      _: 1
    }, 8, ["onClick"])])]),
    default: _withCtx(() => [_createVNode(_component_a_form, {
      ref: "storeForm",
      inline: true,
      rules: _ctx.storeData.storeFormRules,
      model: _ctx.storeData.storeFormData,
      "show-message": false,
      labelAlign: 'left',
      "label-col": {
        span: 4
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_a_form_item, {
        style: {
          "width": "100%",
          "margin-right": "0px"
        },
        name: "storeName",
        label: _ctx.$t('storeManage.storeName')
      }, {
        default: _withCtx(() => [_createVNode(_component_a_input, {
          ref: "storeNameInput",
          value: _ctx.storeData.storeFormData.storeName,
          "onUpdate:value": _cache[8] || (_cache[8] = $event => _ctx.storeData.storeFormData.storeName = $event),
          placeholder: _ctx.$t('storeManage.inputStoreName')
        }, null, 8, ["value", "placeholder"])]),
        _: 1
      }, 8, ["label"]), _createVNode(_component_a_form_item, {
        style: {
          "width": "100%",
          "margin-right": "0px"
        },
        name: "storeCode",
        label: _ctx.$t('storeManage.storeCode')
      }, {
        default: _withCtx(() => [_createVNode(_component_a_input, {
          value: _ctx.storeData.storeFormData.storeCode,
          "onUpdate:value": _cache[9] || (_cache[9] = $event => _ctx.storeData.storeFormData.storeCode = $event)
        }, null, 8, ["value"])]),
        _: 1
      }, 8, ["label"]), _createVNode(_component_a_form_item, {
        style: {
          "width": "100%",
          "margin-right": "0px"
        },
        name: "storeAddress",
        label: _ctx.$t('storeManage.storeAddress')
      }, {
        default: _withCtx(() => [_createVNode(_component_a_input, {
          value: _ctx.storeData.storeFormData.storeAddress,
          "onUpdate:value": _cache[10] || (_cache[10] = $event => _ctx.storeData.storeFormData.storeAddress = $event),
          placeholder: _ctx.$t('storeManage.inputStoreAddress')
        }, null, 8, ["value", "placeholder"])]),
        _: 1
      }, 8, ["label"]), _createVNode(_component_a_form_item, {
        style: {
          "width": "100%",
          "margin-right": "0px"
        },
        name: "storeTypeId",
        label: _ctx.$t('storeManage.storeType')
      }, {
        default: _withCtx(() => [_createVNode(_component_a_select, {
          value: _ctx.storeData.storeFormData.storeTypeId,
          "onUpdate:value": _cache[11] || (_cache[11] = $event => _ctx.storeData.storeFormData.storeTypeId = $event),
          options: _ctx.storeData.storeTypeData,
          "field-names": _ctx.storeData.storeTypeProps,
          placeholder: _ctx.$t('storeManage.selectStoreTypeHint')
        }, {
          suffixIcon: _withCtx(() => [_createVNode(_component_down_circle_outlined, {
            class: "ant-select-suffix",
            style: {
              "color": "#818181"
            }
          })]),
          _: 1
        }, 8, ["value", "options", "field-names", "placeholder"])]),
        _: 1
      }, 8, ["label"]), _createVNode(_component_a_form_item, {
        style: {
          "width": "100%",
          "margin-right": "0px"
        },
        name: "city",
        label: _ctx.$t('storeManage.city')
      }, {
        default: _withCtx(() => [_createVNode(_component_a_input, {
          value: _ctx.storeData.storeFormData.city,
          "onUpdate:value": _cache[12] || (_cache[12] = $event => _ctx.storeData.storeFormData.city = $event),
          placeholder: _ctx.$t('storeManage.inputCity')
        }, null, 8, ["value", "placeholder"])]),
        _: 1
      }, 8, ["label"]), _createVNode(_component_a_form_item, {
        style: {
          "width": "100%",
          "margin-right": "0px"
        },
        name: "contactName",
        label: _ctx.$t('storeManage.contactPerson')
      }, {
        default: _withCtx(() => [_createVNode(_component_a_input, {
          value: _ctx.storeData.storeFormData.contactName,
          "onUpdate:value": _cache[13] || (_cache[13] = $event => _ctx.storeData.storeFormData.contactName = $event),
          placeholder: _ctx.$t('storeManage.inputContactPerson')
        }, null, 8, ["value", "placeholder"])]),
        _: 1
      }, 8, ["label"]), _createVNode(_component_a_form_item, {
        style: {
          "width": "100%",
          "margin-right": "0px"
        },
        name: "contactMobile",
        label: _ctx.$t('storeManage.contactNumber')
      }, {
        default: _withCtx(() => [_createVNode(_component_a_input, {
          value: _ctx.storeData.storeFormData.contactMobile,
          "onUpdate:value": _cache[14] || (_cache[14] = $event => _ctx.storeData.storeFormData.contactMobile = $event),
          placeholder: _ctx.$t('storeManage.inputContactNumber')
        }, null, 8, ["value", "placeholder"])]),
        _: 1
      }, 8, ["label"]), _createVNode(_component_a_form_item, {
        style: {
          "width": "100%",
          "margin-right": "0px"
        },
        name: "contactEmail",
        label: _ctx.$t('storeManage.email')
      }, {
        default: _withCtx(() => [_createVNode(_component_a_input, {
          value: _ctx.storeData.storeFormData.contactEmail,
          "onUpdate:value": _cache[15] || (_cache[15] = $event => _ctx.storeData.storeFormData.contactEmail = $event),
          placeholder: _ctx.$t('storeManage.inputEmail')
        }, null, 8, ["value", "placeholder"])]),
        _: 1
      }, 8, ["label"]), _createVNode(_component_a_form_item, {
        style: {
          "width": "100%",
          "margin-right": "0px"
        },
        name: "storeStatusId",
        label: _ctx.$t('storeManage.storeStatus')
      }, {
        default: _withCtx(() => [_createVNode(_component_a_select, {
          "show-search": "",
          value: _ctx.storeData.storeFormData.storeStatusId,
          "onUpdate:value": _cache[16] || (_cache[16] = $event => _ctx.storeData.storeFormData.storeStatusId = $event),
          options: _ctx.storeData.storeStatusData,
          "field-names": _ctx.storeData.storeStatusProps,
          placeholder: _ctx.$t('storeManage.selectStoreStatus')
        }, {
          suffixIcon: _withCtx(() => [_createVNode(_component_down_circle_outlined, {
            class: "ant-select-suffix",
            style: {
              "color": "#818181"
            }
          })]),
          _: 1
        }, 8, ["value", "options", "field-names", "placeholder"])]),
        _: 1
      }, 8, ["label"])]),
      _: 1
    }, 8, ["rules", "model"])]),
    _: 1
  }, 8, ["open"]), _createVNode(_component_ex_dialog, {
    extraStyle: "min-width: 380px;\n      max-width: 600px;\n      width: 80%;\n      height: 400px;\n      font-size: 14px;\n      padding:10px",
    isVisible: _ctx.storeData.importMessageVisible
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.$t('storeManage.importValidationError')), 1), _createVNode(_component_el_divider), _createElementVNode("div", _hoisted_32, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.storeData.importMessages, (message, index) => {
      return _openBlock(), _createElementBlock("div", {
        key: index
      }, _toDisplayString(message), 1);
    }), 128))]), _createElementVNode("div", _hoisted_33, [_createVNode(_component_a_button, {
      onClick: _cache[18] || (_cache[18] = $event => _ctx.storeData.importMessageVisible = false),
      style: {
        "border-color": "black",
        "color": "black"
      },
      class: "sts-button default"
    }, {
      default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$t('storeManage.close')), 1)]),
      _: 1
    })])]),
    _: 1
  }, 8, ["isVisible"])]);
}