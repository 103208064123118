import "core-js/modules/es.array.push.js";
import { reactive, ref, getCurrentInstance, onMounted, onUnmounted, nextTick } from "vue";
import SvgIcon from "../components/SvgIcon.vue";
import util from "@/util/util";
import { genFileId, ElLoading } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import useClipboard from "vue-clipboard3";
import permission from "@/util/permission";
import store from "../store";
import cookies from "js-cookie";
import { DownCircleOutlined, PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import AMapLoader from "@amap/amap-jsapi-loader";
import { useI18n } from "vue-i18n";

import { ElNotification, ElMessageBox, ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
import 'element-plus/es/components/message-box/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  name: "InstallPlan",
  components: {
    SvgIcon,
    DownCircleOutlined
  },
  setup() {
    const {
      t,
      locale
    } = useI18n();
    const {
      proxy
    } = getCurrentInstance();
    const {
      toClipboard
    } = useClipboard();
    const router = useRouter();
    const route = useRoute();
    const activityId = route.params.activityId;
    const vendorId = route.params.vendorId;
    const tokenData = util.getToken();
    let installConveyData = {};
    try {
      installConveyData = JSON.parse(cookies.get("installConvey_" + activityId));
    } catch {}
    const importMessage = reactive({
      importMessageVisible: false,
      importMessages: []
    });
    const deliveredControl = reactive({
      allChecked: false,
      isOpenBatch: false
    });
    const activeName = ref("first");
    const uploadInstallPane = ref(null);
    const installTable = ref();
    // 分页
    const installPaginationData = reactive({
      pageSizes: [25, 50, 100],
      currentPage: 1,
      totalSize: 0,
      pageSize: 25
    });

    // 安装进度列表
    const cityData = reactive([]);
    const provinceDictData = reactive([]);
    const activityData = reactive([]);
    const storeTypeData = reactive([]);
    const storeTypeInstallationData = reactive([]);
    const storeTypeLogisticsData = reactive([]);
    const vendorData = reactive([]);
    const vendorTypeData = reactive([]);
    const installationStatusData = reactive([]);
    const getInstallationConfirmData = reactive([]);
    const installationData = reactive({
      data: []
    });
    const uploadInstallData = reactive({
      signatureData: {
        accessId: null,
        policy: null,
        signature: null,
        dir: null,
        host: "",
        expire: null,
        uuid: null
      },
      fileList: [],
      data: {},
      lastUpload: {},
      cacheData: []
    });
    const uploadInstallPopoverVisible = ref(false);
    const installQuery = reactive({
      typeIndex: 1,
      insValue: "",
      insSelect: {
        storeType: null,
        vendorId: null,
        vendorTypeId: null,
        installationStatusId: null,
        installationResultId: null,
        provinceCity: null,
        areaInput: null,
        installationConfirmStatusId: null,
        activityId: null
      }
    });
    //分页Select变化
    const paginationSizeInstallChange = val => {
      let data = {
        installationResultId: installQuery.insSelect.installationResultId,
        installationStatusId: installQuery.insSelect.installationStatusId,
        searchKeyword: installQuery.insValue,
        storeType: installQuery.insSelect.storeType,
        vendorId: installQuery.insSelect.vendorId,
        activityId: installQuery.insSelect.activityId,
        vendorTypeId: installQuery.insSelect.vendorTypeId,
        installationConfirmStatusId: installQuery.insSelect.installationConfirmStatusId
      };
      if (Array.isArray(installQuery.insSelect.provinceCity)) {
        data.province = installQuery.insSelect.provinceCity[0];
        if (installQuery.insSelect.provinceCity.length > 1) {
          data.city = installQuery.insSelect.provinceCity[1];
        }
      }
      getInstallationPageApi(data, installPaginationData.currentPage, installPaginationData.pageSize);
    };
    //分页页数变化
    const paginationCurrentInstallChange = val => {
      let data = {
        installationResultId: installQuery.insSelect.installationResultId,
        installationStatusId: installQuery.insSelect.installationStatusId,
        searchKeyword: installQuery.insValue,
        storeType: installQuery.insSelect.storeType,
        vendorId: installQuery.insSelect.vendorId,
        activityId: installQuery.insSelect.activityId,
        vendorTypeId: installQuery.insSelect.vendorTypeId,
        installationConfirmStatusId: installQuery.insSelect.installationConfirmStatusId
      };
      if (Array.isArray(installQuery.insSelect.provinceCity)) {
        data.province = installQuery.insSelect.provinceCity[0];
        if (installQuery.insSelect.provinceCity.length > 1) {
          data.city = installQuery.insSelect.provinceCity[1];
        }
      }
      getInstallationPageApi(data, installPaginationData.currentPage, installPaginationData.pageSize);
    };
    //btn点击事件
    const openUploadInstallPopover = () => {
      uploadInstallPane.value.clearFiles();
      uploadInstallPopoverVisible.value = true;
    };
    //当超出限制时，执行的钩子函数
    const handleInstallExceed = files => {
      uploadInstallPane.value.clearFiles();
      const file = files[0];
      file.uid = genFileId();
      uploadInstallPane.value.handleStart(file);
      uploadInstallPane.value.submit();
    };
    //获取文件签名等信息
    const getSignature = async directory => {
      console.log(directory);
      return new Promise((resolve, reject) => {
        proxy.$api.authorizationRequest.getSignature(directory).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          console.log(error);
          reject(error);
        });
      });
    };
    //上传文件之前的钩子
    const handleInstallBeforeUpload = async file => {
      uploadInstallData.signatureData = await getSignature("__temporary");
      let filename = file.name;
      let fullName = uploadInstallData.signatureData.uuid + util.getSuffix(filename);
      let keyValue = uploadInstallData.signatureData.dir + fullName;
      uploadInstallData.data = {
        name: file.name,
        key: keyValue,
        policy: uploadInstallData.signatureData.policy,
        OSSAccessKeyId: uploadInstallData.signatureData.accessId,
        signature: uploadInstallData.signatureData.signature,
        success_action_status: "200",
        onSuccess: file.uid
      };
      uploadInstallData.lastUpload = {
        uid: file.uid,
        filename: fullName
      };
    };
    //文件上传成功的钩子
    const handleInstallOnSuccess = async (response, file, files) => {
      if (file.status === "success") {
        let activityUploadedRO = {
          activityId: activityId,
          uploadedRO: {
            fileName: uploadInstallData.data.name,
            uploadedName: uploadInstallData.data.key
          }
        };
        importInstallationRequest(activityUploadedRO);
      }
    };
    const importInstallationRequest = async activityUploadedRO => {
      const loadingInstance = ElLoading.service({
        lock: true,
        text: t('installConvey.importingBatchInstall'),
        background: "rgba(0, 0, 0, 0.7)"
      });
      proxy.$api.activityRequest.importInstallation(activityUploadedRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202163") {
            uploadInstallPopoverVisible.value = false;
            ElNotification({
              title: "success",
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "success",
              duration: 3000
            });
          } else if (res.result.dataCode === "202177") {
            uploadInstallPopoverVisible.value = false;
            importMessage.importMessageVisible = true;
            importMessage.importMessages = res.result.data;
          } else {
            ElNotification({
              title: t('installConvey.failure'),
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: t('installConvey.failure'),
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
        nextTick(() => {
          loadingInstance.close();
        });
      }).catch(error => {
        ElNotification({
          title: "error",
          message: error,
          type: "error",
          duration: 3000
        });
        nextTick(() => {
          loadingInstance.close();
        });
      });
    };
    const installLoading = ref(false);
    //时间戳转时间
    const getDate = data => {
      let date = new Date(data); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + "-";
      let M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      let D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      let h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      let m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      let s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    };
    //表头排序
    const headerCellInstallClickEvent = ({
      column,
      triggerResizable,
      triggerSort,
      triggerFilter
    }) => {
      const $table = installTable.value;
      if ($table) {
        // 如果触发了列的其他功能按钮
        if (column.sortable && !(triggerResizable || triggerSort || triggerFilter)) {
          if (column.order === "desc") {
            $table.clearSort();
          } else if (column.order === "asc") {
            $table.sort(column.field, "desc");
          } else {
            $table.sort(column.field, "asc");
          }
        }
      }
    };
    //获取安装进度列表
    const getInstallationPageApi = async (data, pageIndex, pageSize, add) => {
      installLoading.value = true;
      let logisticsPageRO = {
        data: data,
        pageIndex: pageIndex,
        pageSize: pageSize
      };
      window.localStorage.setItem("installation_page_param", JSON.stringify(logisticsPageRO));
      let callApi = proxy.$api.scheduleRequest.getInstallationPage;
      if (vendorId == "" && tokenData.type === 1) {
        callApi = proxy.$api.scheduleRequest.getStoreInstallationPage;
      }
      callApi(logisticsPageRO).then(async res => {
        console.log(res);
        if (res.code === "200000") {
          if (res.result.dataCode === "202250") {
            let msg = res.result.data.pageList;
            if (msg) {
              for (let install of msg) {
                const uniqueStoreLocations = install.storeLocations.reduce((accumulator, current) => {
                  const existingObject = accumulator.find(item => item.installationResponsibleName === current.installationResponsibleName);
                  if (!existingObject) {
                    accumulator.push(current);
                  }
                  return accumulator;
                }, []);
                install.activityInstallations = uniqueStoreLocations;
              }
              if (add) {
                installationData.data.push(...msg);
                installPaginationData.totalSize = res.result.data.totalCount;
              } else {
                installationData.data = msg;
                installPaginationData.totalSize = res.result.data.totalCount;
              }
            } else {
              installationData.data = msg;
              installPaginationData.totalSize = res.result.data.totalCount;
            }
          } else {
            ElNotification({
              title: t('installConvey.failure'),
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: t('installConvey.failure'),
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
        installLoading.value = false;
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          console.log(error);
        } else {
          ElNotification({
            title: t('installConvey.failure'),
            message: error,
            type: "error",
            duration: 3000
          });
        }
        installLoading.value = false;
      });
    };
    const openInstallationEvent = () => {
      let installationPageRO = {
        installationResultId: installQuery.insSelect.installationResultId,
        installationStatusId: installQuery.insSelect.installationStatusId,
        searchKeyword: installQuery.insValue,
        activityId: installQuery.insSelect.activityId,
        storeType: installQuery.insSelect.storeType,
        vendorId: installQuery.insSelect.vendorId,
        vendorTypeId: installQuery.insSelect.vendorTypeId
      };
      if (Array.isArray(installQuery.insSelect.provinceCity)) {
        installationPageRO.province = installQuery.insSelect.provinceCity[0];
        if (installQuery.insSelect.provinceCity.length > 1) {
          installationPageRO.city = installQuery.insSelect.provinceCity[1];
        }
      }
      getInstallationExport(installationPageRO);
    };
    const getInstallationExport = installationPageRO => {
      const loadingInstance = ElLoading.service({
        lock: true,
        text: t('installConvey.exportingTemplate'),
        background: "rgba(0, 0, 0, 0.7)"
      });
      proxy.$api.scheduleRequest.getInstallationExport(installationPageRO).then(res => {
        nextTick(() => {
          loadingInstance.close();
        });
      }).catch(error => {
        ElNotification({
          title: "error",
          message: error,
          type: "error",
          duration: 3000
        });
        nextTick(() => {
          loadingInstance.close();
        });
      });
    };
    // 获取所有店铺城市
    const getStoreCity = async storeCityRO => {
      return new Promise((resolve, reject) => {
        proxy.$api.storeRequest.getStoreCity(storeCityRO, true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: t('installConvey.failure'),
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const getProvinceDict = async storeActivityVendorRO => {
      return new Promise((resolve, reject) => {
        proxy.$api.storeRequest.getProvinceDictByActivity(storeActivityVendorRO, true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: t('installConvey.failure'),
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    // 获取所有店铺省份
    const getStoreProvince = async activityId => {
      return new Promise((resolve, reject) => {
        proxy.$api.storeRequest.getStoreProvince(activityId, true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: t('installConvey.failure'),
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    // 获取所有店铺类型
    const getStoreType = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.storeRequest.getStoreType(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: t('installConvey.failure'),
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const getStoreTypeByInstallation = async storeActivityVendorRO => {
      return new Promise((resolve, reject) => {
        proxy.$api.storeRequest.getStoreTypeByInstallation(storeActivityVendorRO, true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: t('installConvey.failure'),
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const getStoreTypeByLogistics = async storeActivityVendorRO => {
      return new Promise((resolve, reject) => {
        proxy.$api.storeRequest.getStoreTypeByLogistics(storeActivityVendorRO, true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: t('installConvey.failure'),
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    // 获取所有供应商
    const getVendor = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.vendorRequest.getVendorAll(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: t('installConvey.failure'),
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    // 获取所有供应商类型
    const getVendorType = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.vendorRequest.getVendorType(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: t('installConvey.failure'),
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };

    // 获取所有安装状态
    const getInstallationStatus = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.scheduleRequest.getInstallationStatus(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: t('installConvey.failure'),
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };

    // 获取所有验收状态
    const getInstallationConfirm = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.repairRequest.getInstallationResult(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: t('installConvey.failure'),
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const selectAllActivity = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.activityRequest.selectAllActivity(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: t('installConvey.failure'),
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };

    //搜索点击事件
    const searchInstallClick = async linkageType => {
      let storeActivityVendorRO = {
        activityId: installQuery.insSelect.activityId,
        vendorId: installQuery.insSelect.vendorId,
        storeTypeId: installQuery.insSelect.storeType
      };
      if (linkageType === 1) {
        provinceDictData.length = 0;
        // 获取活动相关所有省份
        var provinceDictList = await getProvinceDict(storeActivityVendorRO);
        if (provinceDictList == null) {
          return;
        } else {
          provinceDictList = provinceDictList.map(provinceItem => ({
            value: provinceItem.province,
            label: provinceItem.province,
            children: provinceItem.cities.map(cityItem => ({
              value: cityItem.city,
              label: cityItem.city
            }))
          }));
        }
        provinceDictData.push(...provinceDictList);
      } else if (linkageType === 2) {
        if (Array.isArray(installQuery.insSelect.provinceCity)) {
          storeActivityVendorRO.province = installQuery.insSelect.provinceCity[0];
          if (installQuery.insSelect.provinceCity.length > 1) {
            storeActivityVendorRO.city = installQuery.insSelect.provinceCity[1];
          }
        }
        storeTypeInstallationData.length = 0;
        // 获取指定活动安装所有店铺类型
        let storeTypeInstallationList = await getStoreTypeByInstallation(storeActivityVendorRO);
        if (storeTypeInstallationList == null) {
          return;
        }
        storeTypeInstallationData.push(...storeTypeInstallationList);
      }
      let data = {
        installationResultId: installQuery.insSelect.installationResultId,
        installationStatusId: installQuery.insSelect.installationStatusId,
        searchKeyword: installQuery.insValue,
        activityId: installQuery.insSelect.activityId,
        storeType: installQuery.insSelect.storeType,
        vendorId: installQuery.insSelect.vendorId,
        vendorTypeId: installQuery.insSelect.vendorTypeId,
        installationConfirmStatusId: installQuery.insSelect.installationConfirmStatusId
      };
      if (Array.isArray(installQuery.insSelect.provinceCity)) {
        data.province = installQuery.insSelect.provinceCity[0];
        if (installQuery.insSelect.provinceCity.length > 1) {
          data.city = installQuery.insSelect.provinceCity[1];
        }
      }
      installPaginationData.currentPage = 1;
      getInstallationPageApi(data, 1, installPaginationData.pageSize);
    };
    // 店铺信息复制
    const copyStore = async row => {
      let text = t('installConvey.address') + "：" + row.storeAddress + "\n" + t('installConvey.contact') + "：" + row.storeContactName + "\n" + t('installConvey.phone') + "：" + row.storeContactMobile;
      console.log(text);
      try {
        await toClipboard(text); //实现复制
        console.log("Success");
        ElNotification({
          title: "success",
          message: t('installConvey.copyToClipboard'),
          type: "success",
          duration: 3000
        });
      } catch (e) {
        console.error(e);
      }
    };
    // 供应商信息复制
    const copyVendor = async row => {
      let text = "";
      if (Array.isArray(row.activityInstallations)) {
        for (let install of row.activityInstallations) {
          text += t('installConvey.contact') + "：" + install.installationResponsibleName + "\n" + t('installConvey.phone') + "：" + row.phoneNumber;
        }
      }
      console.log(text);
      try {
        await toClipboard(text); //实现复制
        console.log("Success");
        ElNotification({
          title: "success",
          message: t('installConvey.copyToClipboard'),
          type: "success",
          duration: 3000
        });
      } catch (e) {
        console.error(e);
      }
    };
    // 验收信息复制
    const copyResult = async row => {
      let text = t('installConvey.contact') + "：" + row.installationResultContactName + "\n" + t('installConvey.phone') + "：" + row.installationResultContactMobile;
      console.log(text);
      try {
        await toClipboard(text); //实现复制
        console.log("Success");
        ElNotification({
          title: "success",
          message: t('installConvey.copyToClipboard'),
          type: "success",
          duration: 3000
        });
      } catch (e) {
        console.error(e);
      }
    };
    const installTableScroll = ({
      type,
      scrollTop,
      scrollLeft,
      scrollHeight,
      scrollWidth,
      bodyWidth,
      bodyHeight,
      isX,
      isY,
      $event
    }) => {
      let mediaQuery = window.matchMedia("(max-width: 768px)");
      if (mediaQuery.matches) {
        if (scrollTop + bodyHeight >= scrollHeight - 20) {
          let totalCount = Math.ceil(installPaginationData.totalSize / installPaginationData.pageSize);
          console.log("-------------------" + installPaginationData.currentPage < totalCount);
          if (installPaginationData.currentPage < totalCount) {
            installPaginationData.currentPage++;
            let data = {
              installationResultId: installQuery.insSelect.installationResultId,
              installationStatusId: installQuery.insSelect.installationStatusId,
              searchKeyword: installQuery.insValue,
              storeType: installQuery.insSelect.storeType,
              vendorId: installQuery.insSelect.vendorId,
              vendorTypeId: installQuery.insSelect.vendorTypeId
            };
            if (Array.isArray(installQuery.insSelect.provinceCity)) {
              data.province = installQuery.insSelect.provinceCity[0];
              if (installQuery.insSelect.provinceCity.length > 1) {
                data.city = installQuery.insSelect.provinceCity[1];
              }
            }
            getInstallationPageApi(data, installPaginationData.currentPage, installPaginationData.pageSize, "add");
          }
        }
      }
    };
    const goConveyPlan = () => {
      if (vendorId) {
        router.push({
          path: "/convey-plan/" + activityId + "/" + vendorId
        });
      } else {
        router.push({
          path: "/convey-plan/" + activityId
        });
      }
    };
    var map = null;
    var _AMap = null;
    onMounted(async () => {
      let windowWidth = window.innerWidth;
      console.log(windowWidth);
      if (windowWidth <= 768) {
        installPaginationData.pageSize = 20;
        conveyPaginationData.pageSize = 20;
      }
      if (vendorId) {
        installQuery.insSelect.vendorId = parseInt(vendorId);
        conveyQuery.insSelect.vendorId = parseInt(vendorId);
      }
      if (activityId) {
        installQuery.insSelect.activityId = parseInt(activityId);
        conveyQuery.insSelect.activityId = parseInt(activityId);
      }
      const installationPageParam = window.localStorage.getItem("installation_page_param");
      if (installationPageParam != null) {
        let param = JSON.parse(installationPageParam);
        if (param.data.vendorId === installQuery.insSelect.vendorId && param.data.activityId === installQuery.insSelect.activityId) {
          installQuery.insValue = param.data.searchKeyword;
          installQuery.insSelect.installationResultId = param.data.installationResultId;
          installQuery.insSelect.installationStatusId = param.data.installationStatusId;
          installQuery.insSelect.activityId = param.data.activityId;
          installQuery.insSelect.storeType = param.data.storeType;
          installQuery.insSelect.vendorId = param.data.vendorId;
          installQuery.insSelect.vendorTypeId = param.data.vendorTypeId;
          installQuery.insSelect.installationConfirmStatusId = param.data.installationConfirmStatusId;
          if (param.data.province != null) {
            installQuery.insSelect.provinceCity = [param.data.province];
            if (param.data.city != null) {
              installQuery.insSelect.provinceCity.push(param.data.city);
            }
          }
          installPaginationData.currentPage = param.pageIndex;
          installPaginationData.pageSize = param.pageSize;
          getInstallationPageApi(param.data, param.pageIndex, param.pageSize);
        } else {
          getInstallationPageApi({
            vendorId: installQuery.insSelect.vendorId,
            activityId: installQuery.insSelect.activityId
          }, installPaginationData.currentPage, installPaginationData.pageSize);
        }
      } else {
        getInstallationPageApi({
          vendorId: installQuery.insSelect.vendorId,
          activityId: installQuery.insSelect.activityId
        }, installPaginationData.currentPage, installPaginationData.pageSize);
      }
      getLogisticsPageApi({
        searchKeyword: conveyQuery.insValue,
        activityId: conveyQuery.insSelect.activityId,
        storeTypeId: conveyQuery.insSelect.storeType,
        vendorId: conveyQuery.insSelect.vendorId,
        city: conveyQuery.insSelect.areaInput,
        logisticsTypeId: conveyQuery.insSelect.logisticsTypeId,
        expressStateCode: conveyQuery.insSelect.state
      }, conveyPaginationData.currentPage, conveyPaginationData.pageSize);
      // 获取活动相关所有店铺
      let cityList = await getStoreCity({
        activityId: activityId,
        storeTypeId: conveyQuery.insSelect.storeType
      });
      if (cityList == null) {
        return;
      }
      cityData.push(...cityList);
      // 获取活动相关所有省份
      let storeActivityVendorRO = {
        activityId: installQuery.insSelect.activityId,
        vendorId: installQuery.insSelect.vendorId,
        storeTypeId: installQuery.insSelect.storeType
      };
      if (Array.isArray(installQuery.insSelect.provinceCity)) {
        storeActivityVendorRO.province = installQuery.insSelect.provinceCity[0];
        if (installQuery.insSelect.provinceCity.length > 1) {
          storeActivityVendorRO.city = installQuery.insSelect.provinceCity[1];
        }
      }
      var provinceDictList = await getProvinceDict(storeActivityVendorRO);
      if (provinceDictList == null) {
        return;
      } else {
        provinceDictList = provinceDictList.map(provinceItem => ({
          value: provinceItem.province,
          label: provinceItem.province,
          children: provinceItem.cities.map(cityItem => ({
            value: cityItem.city,
            label: cityItem.city
          }))
        }));
      }
      provinceDictData.push(...provinceDictList);
      // 获取所有活动
      let activityList = await selectAllActivity();
      if (activityList == null) {
        return;
      }
      let findIndex = activityList.findIndex(element => element.activityId == activityId);
      if (findIndex != -1) {
        store.commit("setHeaderTitle", activityList[findIndex].activityName);
      }
      activityData.push(...activityList);
      // // 获取所有店铺类型
      // let storeTypeList = await getStoreType();
      // if (storeTypeList == null) {
      //   return;
      // }
      // storeTypeData.push(...storeTypeList);
      // 获取指定活动安装所有店铺类型
      let storeTypeInstallationList = await getStoreTypeByInstallation(storeActivityVendorRO);
      if (storeTypeInstallationList == null) {
        return;
      }
      storeTypeInstallationData.push(...storeTypeInstallationList);
      // 获取指定活动物流所有店铺类型
      let logisticsStoreActivityVendorRO = {
        activityId: conveyQuery.insSelect.activityId,
        vendorId: conveyQuery.insSelect.vendorId
      };
      let storeTypeLogisticsList = await getStoreTypeByLogistics(logisticsStoreActivityVendorRO);
      if (storeTypeLogisticsList == null) {
        return;
      }
      storeTypeLogisticsData.push(...storeTypeLogisticsList);
      // 获取所有供应商
      let vendorList = await getVendor();
      if (vendorList == null) {
        return;
      }
      vendorData.push(...vendorList);
      // 获取所有供应商类型
      let vendorTypeList = await getVendorType();
      if (vendorTypeList == null) {
        return;
      }
      vendorTypeData.push(...vendorTypeList);
      // 获取所有安装状态
      let installationStatusList = await getInstallationStatus();
      if (installationStatusList == null) {
        return;
      }
      installationStatusData.push(...installationStatusList);
      // 获取所有验收状态
      let getInstallationConfirmList = await getInstallationConfirm();
      if (getInstallationConfirmList == null) {
        return;
      }
      getInstallationConfirmData.push(...getInstallationConfirmList);
      // 获取所有物流状态
      let getExpressStateList = await getExpressState();
      if (getExpressStateList == null) {
        return;
      }
      for (let i = 0; i < getExpressStateList.length; i++) {
        if (parseInt(getExpressStateList[i].expressStateId) <= 10) {
          expressStateListData.push(getExpressStateList[i]);
        }
      }
      // expressStateListData.push(...getExpressStateList);
      // 获取所有物流类型
      let getLogisticsTypeList = await getLogisticsType();
      if (getLogisticsTypeList == null) {
        return;
      }
      logisticsTypeData.push(...getLogisticsTypeList);

      //////////地图
      window._AMapSecurityConfig = {
        securityJsCode: "e8af5eeded2ec8c2207de015d14fbc27"
      };
      AMapLoader.load({
        key: "3d15e751cca6552c88c1657141ab43f0",
        // 申请好的Web端开发者Key
        version: "2.0",
        // 指定要加载的 JSAPI 的版本
        plugins: ["AMap.Driving"] // 需要使用的插件列表
      }).then(AMap => {
        _AMap = AMap;
      }).catch(e => {
        console.log(e);
      });
    });
    onUnmounted(() => {});
    const expressInfoOpen = ref(false);
    const currentExpress = ref(null);
    const onExpressInfoOpen = row => {
      currentExpress.value = row;
      expressInfoOpen.value = true;
      if (Array.isArray(row.expressCoordinates) && row.expressCoordinates.length > 0) {
        initExpressMap(row, true);
      }
    };
    const onExpressInfoClose = () => {
      currentExpress.value = null;
      expressInfoOpen.value = false;
      initExpressMap(false);
    };
    const initExpressMap = async (express, visible) => {
      if (visible === true) {
        await nextTick();
        if (map) {
          map.destroy();
          map = null;
        }
        map = new _AMap.Map("container", {
          zoom: 10,
          center: [23.15815, 112.56706] // 初始中心点
        });

        var startPoint = null;
        var endPoint = null;
        if (express.senderCityLatAndLng != null) {
          let sLngLat = express.senderCityLatAndLng.split(",").map(Number).reverse();
          startPoint = new _AMap.LngLat(sLngLat[0], sLngLat[1]); // 发货地点经纬度
        }

        if (express.receiverCityLatAndLng != null) {
          let rLngLat = express.receiverCityLatAndLng.split(",").map(Number).reverse();
          endPoint = new _AMap.LngLat(rLngLat[0], rLngLat[1]); // 收货地点经纬度
        }
        // 定义发货地点、收货地点和途经轨迹点
        const waypoints = []; // 途经轨迹点

        for (let expressCoordinates of express.expressCoordinates) {
          let eLngLat = expressCoordinates.latAndLng.split(",").map(Number).reverse();
          waypoints.push(new _AMap.LngLat(eLngLat[0], eLngLat[1]));
        }

        // // 绘制发货地点
        // new AMap.Marker({
        //   position: startPoint,
        //   map: map,
        //   label: {
        //     offset: new AMap.Pixel(20, 20),
        //     content: "发货地点",
        //   },
        // });

        // // 绘制收货地点
        // new AMap.Marker({
        //   position: endPoint,
        //   map: map,
        //   label: {
        //     offset: new AMap.Pixel(20, 20),
        //     content: "收货地点",
        //   },
        // });

        var customIcon = new _AMap.Icon({
          // 图标尺寸
          size: new _AMap.Size(60, 30),
          // 图标的取图地址
          image: "https://aqualpublic.oss-cn-shanghai.aliyuncs.com/weapp/transporter%202.png",
          // 替换为你的图片URL
          // 图标所用图片大小
          imageSize: new _AMap.Size(60, 30)
        });

        // 绘制途经轨迹点
        waypoints.forEach((waypoint, index) => {
          if (index === waypoints.length - 1) {
            new _AMap.Marker({
              position: waypoint,
              map: map,
              icon: customIcon,
              offset: new _AMap.Pixel(-30, -15)
            });
          }
        });

        // 使用高德地图驾车路线规划服务
        const driving = new _AMap.Driving({
          map: map,
          panel: null,
          // 若不需要路线规划面板，可以省略此项
          hideMarkers: true
        });
        //快递鸟返回的经纬度是纬度在前，经度在后，所以需要调换下位置
        driving.search(waypoints[0], waypoints[waypoints.length - 1], {
          waypoints: []
        }, function (status, result) {
          console.log("result=", result);
          // result 即是对应的驾车导航信息，相关数据结构文档请参考 https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
          if (status === "complete") {
            if (result.routes && result.routes.length) {
              // 绘制第一条路线，也可以按需求绘制其它几条路线
              console.log(result.routes);
              drawRoute(result.routes[0]);
            }
            console.log("绘制驾车路线完成");
          } else {
            console.log("获取驾车数据失败：" + result);
          }
        });
        function drawRoute(route) {
          const path = parseRouteToPath(route);
          const startMarker = new _AMap.Marker({
            position: path[0],
            icon: "https://webapi.amap.com/theme/v1.3/markers/n/start.png",
            map: map
          });
          if (express.expressStateName == "已签收") {
            const endMarker = new _AMap.Marker({
              position: path[path.length - 1],
              icon: "https://webapi.amap.com/theme/v1.3/markers/n/end.png",
              map: map
            });
          }

          // var route = result.routes[0];
          let flag = false;
          // 遍历每个步骤并设置路线样式
          route.steps.forEach(function (step, index) {
            if (flag == false) {
              if (step.action == "") {
                flag = true;
              } else {
                flag = false;
              }
            }

            // 判断是否为当前位置前的路线
            if (!flag) {
              var polyline = new _AMap.Polyline({
                path: step.path,
                strokeColor: "#00f",
                // 起点到当前位置的路线颜色为蓝色
                strokeOpacity: 1,
                strokeWeight: 4,
                strokeStyle: "solid" // 起点到当前位置的路线样式为实线
              });

              polyline.setMap(map);
            } else {
              var polyline = new _AMap.Polyline({
                path: step.path,
                strokeColor: "#f00",
                // 当前位置到终点的路线颜色为灰色
                strokeOpacity: 1,
                strokeWeight: 4,
                strokeStyle: "dashed" // 当前位置到终点的路线样式为虚线
              });

              polyline.setMap(map);
            }
          });
          // const routeLine = new AMap.Polyline({
          //   path: path,
          //   isOutline: true,
          //   outlineColor: "#ffeeee",
          //   borderWeight: 2,
          //   strokeWeight: 5,
          //   strokeOpacity: 0.9,
          //   strokeColor: "#0091ff",
          //   lineJoin: "round",
          // });

          // map.add(routeLine);

          // 调整视野达到最佳显示区域
          // map.setFitView([startMarker, endMarker, routeLine]);
        }

        // 解析DrivingRoute对象，构造成AMap.Polyline的path参数需要的格式
        function parseRouteToPath(route) {
          const path = [];
          for (let i = 0, l = route.steps.length; i < l; i++) {
            const step = route.steps[i];
            for (let j = 0, n = step.path.length; j < n; j++) {
              path.push(step.path[j]);
            }
          }
          return path;
        }
        // // 调整视野使所有点都可见
        // map.setFitView();
      } else {
        if (map) {
          map.destroy();
          map = null;
        }
      }
    };
    const expandConfig = {
      visibleMethod({
        row
      }) {
        if (row.storeLocations.length == 0) {
          return false;
        }
        return true;
      }
    };
    const installUploadClick = row => {
      if (vendorId == "" && tokenData.type === 1) {
        router.push({
          path: "/store-location/" + row.storeCode,
          query: {
            activityId: activityId
          }
        });
      } else {
        router.push({
          path: "/store-location/" + row.storeCode,
          query: {
            installationId: row.installationId
          }
        });
      }
    };

    //convey
    const uploadConverPane = ref(null);
    const conveyTable = ref();

    // 分页
    const conveyPaginationData = reactive({
      pageSizes: [25, 50, 100],
      currentPage: 1,
      totalSize: 0,
      pageSize: 25
    });
    // 安装进度列表
    const conveyData = reactive({
      data: []
    });
    const expressStateListData = reactive([]);
    const logisticsTypeData = reactive([]);
    const uploadConverData = reactive({
      signatureData: {
        accessId: null,
        policy: null,
        signature: null,
        dir: null,
        host: "",
        expire: null,
        uuid: null
      },
      fileList: [],
      data: {},
      lastUpload: {},
      cacheData: []
    });
    const uploadConverPopoverVisible = ref(false);
    const conveyQuery = reactive({
      typeIndex: 1,
      insValue: "",
      insSelect: {
        storeType: null,
        vendorId: null,
        logisticsTypeId: null,
        state: null,
        areaInput: null
      }
    });
    //分页Select变化
    const paginationSizeConverChange = val => {
      let data = {
        searchKeyword: conveyQuery.insValue,
        activityId: conveyQuery.insSelect.activityId,
        storeTypeId: conveyQuery.insSelect.storeType,
        vendorId: conveyQuery.insSelect.vendorId,
        city: conveyQuery.insSelect.areaInput,
        logisticsTypeId: conveyQuery.insSelect.logisticsTypeId,
        expressStateCode: conveyQuery.insSelect.state
      };
      getLogisticsPageApi(data, conveyPaginationData.currentPage, conveyPaginationData.pageSize);
    };
    //分页页数变化
    const paginationCurrentConverChange = val => {
      let data = {
        searchKeyword: conveyQuery.insValue,
        activityId: conveyQuery.insSelect.activityId,
        storeTypeId: conveyQuery.insSelect.storeType,
        vendorId: conveyQuery.insSelect.vendorId,
        city: conveyQuery.insSelect.areaInput,
        logisticsTypeId: conveyQuery.insSelect.logisticsTypeId,
        expressStateCode: conveyQuery.insSelect.state
      };
      getLogisticsPageApi(data, conveyPaginationData.currentPage, conveyPaginationData.pageSize);
    };

    //btn点击事件
    const openUploadConverPopover = () => {
      uploadConverPane.value.clearFiles();
      uploadConverPopoverVisible.value = true;
    };
    //当超出限制时，执行的钩子函数
    const handleConverExceed = files => {
      uploadConverPane.value.clearFiles();
      const file = files[0];
      file.uid = genFileId();
      uploadConverPane.value.handleStart(file);
      uploadConverPane.value.submit();
    };

    //上传文件之前的钩子
    const handleConverBeforeUpload = async file => {
      uploadConverData.signatureData = await getSignature("__temporary");
      let filename = file.name;
      let fullName = uploadConverData.signatureData.uuid + util.getSuffix(filename);
      let keyValue = uploadConverData.signatureData.dir + fullName;
      uploadConverData.data = {
        name: file.name,
        key: keyValue,
        policy: uploadConverData.signatureData.policy,
        OSSAccessKeyId: uploadConverData.signatureData.accessId,
        signature: uploadConverData.signatureData.signature,
        success_action_status: "200",
        onSuccess: file.uid
      };
      uploadConverData.lastUpload = {
        uid: file.uid,
        filename: fullName
      };
    };
    //文件上传成功的钩子
    const handleConverOnSuccess = async (response, file, files) => {
      if (file.status === "success") {
        const activityUploadedRO = {
          activityId: activityId,
          uploadedRO: {
            fileName: uploadConverData.data.name,
            uploadedName: uploadConverData.data.key
          }
        };
        importConverRequest(activityUploadedRO);
      }
    };
    const importConverRequest = async activityUploadedRO => {
      const loadingInstance = ElLoading.service({
        lock: true,
        text: t('installConvey.uploadSuccess'),
        background: "rgba(0, 0, 0, 0.7)"
      });
      proxy.$api.scheduleRequest.importLogistics(activityUploadedRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202261") {
            uploadConverPopoverVisible.value = false;
            ElNotification({
              title: "success",
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "success",
              duration: 3000
            });
          } else if (res.result.dataCode === "202266") {
            uploadConverPopoverVisible.value = false;
            importMessage.importMessageVisible = true;
            importMessage.importMessages = res.result.data;
          } else {
            ElNotification({
              title: t('installConvey.failure'),
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: t('installConvey.failure'),
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
        nextTick(() => {
          loadingInstance.close();
        });
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "error",
            message: error,
            type: "error",
            duration: 3000
          });
        }
        nextTick(() => {
          loadingInstance.close();
        });
      });
    };
    const conveyLoading = ref(false);

    //表头排序
    const headerCellConverClickEvent = ({
      column,
      triggerResizable,
      triggerSort,
      triggerFilter
    }) => {
      const $table = conveyTable.value;
      if ($table) {
        // 如果触发了列的其他功能按钮
        if (column.sortable && !(triggerResizable || triggerSort || triggerFilter)) {
          if (column.order === "desc") {
            $table.clearSort();
          } else if (column.order === "asc") {
            $table.sort(column.field, "desc");
          } else {
            $table.sort(column.field, "asc");
          }
        }
      }
    };
    //获取发货进度列表
    const getLogisticsPageApi = async (data, pageIndex, pageSize, add) => {
      conveyLoading.value = true;
      let logisticsPageRO = {
        data: data,
        pageIndex: pageIndex,
        pageSize: pageSize
      };
      proxy.$api.scheduleRequest.getLogisticsPage(logisticsPageRO).then(res => {
        console.log(res);
        if (res.code === "200000") {
          if (res.result.dataCode === "202250") {
            let msg = res.result.data.pageList;
            if (msg) {
              if (add) {
                conveyData.data.push(...msg);
                conveyPaginationData.totalSize = res.result.data.totalCount;
              } else {
                conveyData.data = msg;
                conveyPaginationData.totalSize = res.result.data.totalCount;
              }
            } else {
              conveyData.data.length = 0;
              conveyPaginationData.totalSize = res.result.data.totalCount;
            }
            deliveredControl.isOpenBatch = false;
          } else {
            ElNotification({
              title: t('installConvey.failure'),
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: t('installConvey.failure'),
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
        conveyLoading.value = false;
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          console.log(error);
        } else {
          ElNotification({
            title: t('installConvey.failure'),
            message: error,
            type: "error",
            duration: 3000
          });
        }
        conveyLoading.value = false;
      });
    };

    // 获取所有物流状态
    const getExpressState = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.scheduleRequest.getExpressState(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: t('installConvey.failure'),
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };

    // 获取所有确认状态
    const getLogisticsType = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.scheduleRequest.getLogisticsType(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: t('installConvey.failure'),
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };

    //搜索点击事件
    const searchConverClick = async linkageType => {
      if (linkageType === 1) {
        cityData.length = 0;
        // 获取活动相关所有店铺
        let cityList = await getStoreCity({
          activityId: activityId,
          storeTypeId: conveyQuery.insSelect.storeType
        });
        if (cityList == null) {
          return;
        }
        cityData.push(...cityList);
      } else if (linkageType === 2) {
        let logisticsStoreActivityVendorRO = {
          activityId: conveyQuery.insSelect.activityId,
          vendorId: conveyQuery.insSelect.vendorId,
          storeTypeId: conveyQuery.insSelect.storeType,
          city: conveyQuery.insSelect.areaInput
        };
        storeTypeLogisticsData.length = 0;
        let storeTypeLogisticsList = await getStoreTypeByLogistics(logisticsStoreActivityVendorRO);
        if (storeTypeLogisticsList == null) {
          return;
        }
        storeTypeLogisticsData.push(...storeTypeLogisticsList);
      }
      let data = {
        searchKeyword: conveyQuery.insValue,
        activityId: conveyQuery.insSelect.activityId,
        storeTypeId: conveyQuery.insSelect.storeType,
        vendorId: conveyQuery.insSelect.vendorId,
        city: conveyQuery.insSelect.areaInput,
        logisticsTypeId: conveyQuery.insSelect.logisticsTypeId,
        expressStateCode: conveyQuery.insSelect.state
      };
      getLogisticsPageApi(data, 1, conveyPaginationData.pageSize);
    };

    //导出
    const openExportEvent = () => {
      let logisticsPageRO = {
        searchKeyword: conveyQuery.insValue,
        activityId: conveyQuery.insSelect.activityId,
        storeTypeId: conveyQuery.insSelect.storeType,
        vendorId: conveyQuery.insSelect.vendorId,
        city: conveyQuery.insSelect.areaInput,
        logisticsTypeId: conveyQuery.insSelect.logisticsTypeId,
        expressStateId: conveyQuery.insSelect.state
      };
      getLogisticsListExport(logisticsPageRO);
    };
    const getLogisticsListExport = logisticsPageRO => {
      const loadingInstance = ElLoading.service({
        lock: true,
        text: t('installConvey.exportingTemplate'),
        background: "rgba(0, 0, 0, 0.7)"
      });
      proxy.$api.scheduleRequest.getLogisticsListExport(logisticsPageRO).then(res => {
        nextTick(() => {
          loadingInstance.close();
        });
      }).catch(error => {
        ElNotification({
          title: "error",
          message: error,
          type: "error",
          duration: 3000
        });
        nextTick(() => {
          loadingInstance.close();
        });
      });
    };
    const conveyTableScroll = ({
      type,
      scrollTop,
      scrollLeft,
      scrollHeight,
      scrollWidth,
      bodyWidth,
      bodyHeight,
      isX,
      isY,
      $event
    }) => {
      let mediaQuery = window.matchMedia("(max-width: 768px)");
      if (mediaQuery.matches) {
        if (scrollTop + bodyHeight >= scrollHeight - 20) {
          let totalCount = Math.ceil(conveyPaginationData.totalSize / conveyPaginationData.pageSize);
          console.log("-------------------" + conveyPaginationData.currentPage < totalCount);
          if (conveyPaginationData.currentPage < totalCount) {
            console.log("翻页");
            conveyPaginationData.currentPage++;
            let data = {
              searchKeyword: conveyQuery.insValue,
              activityId: conveyQuery.insSelect.activityId,
              storeTypeId: conveyQuery.insSelect.storeType,
              vendorId: conveyQuery.insSelect.vendorId,
              city: conveyQuery.insSelect.areaInput,
              logisticsTypeId: conveyQuery.insSelect.logisticsTypeId,
              expressStateCode: conveyQuery.insSelect.state
            };
            getLogisticsPageApi(data, conveyPaginationData.currentPage, conveyPaginationData.pageSize, "add");
          }
        }
      }
    };
    const batchDeliveredLogistics = () => {
      if (deliveredControl.isOpenBatch == false) {
        deliveredControl.isOpenBatch = true;
      } else {
        const $table = conveyTable.value;
        if ($table) {
          const selectRecords = $table.getCheckboxRecords();
          let checkRecords = [];
          for (let logistics of selectRecords) {
            if (logistics.expressStateName !== "已签收" && logistics.logisticsTypeId !== 1) {
              checkRecords.push(logistics);
            }
          }
          if (checkRecords.length > 0) {
            ElMessageBox.confirm(t('installConvey.confirmDeliveryPrompt'), t('installConvey.ask'), {
              confirmButtonText: t('installConvey.confirm'),
              cancelButtonText: t('installConvey.cancel'),
              type: "warning"
            }).then(() => {
              batchDeliveredLogisticsRequest(checkRecords);
            }).catch(() => {
              ElMessage({
                type: "info",
                message: t('installConvey.cancelDelete')
              });
            });
          } else {
            if ($table) {
              $table.clearCheckboxRow();
            }
            deliveredControl.isOpenBatch = false;
          }
        }
      }
    };
    const deliveredLogistics = row => {
      ElMessageBox.confirm(t('installConvey.confirmDeliveryPrompt'), t('installConvey.ask'), {
        confirmButtonText: t('installConvey.confirm'),
        cancelButtonText: t('installConvey.cancel'),
        type: "warning"
      }).then(() => {
        deliveredLogisticsRequest(row);
      }).catch(() => {
        ElMessage({
          type: "info",
          message: t('installConvey.cancelDelete')
        });
      });
    };
    const selfActivityInstallation = (row, subRow) => {
      const activityInstallationSelfRO = {
        activityInstallationId: subRow.activityInstallationId
      };
      ElMessageBox.confirm(t('installConvey.confirmSelfInstall'), t('installConvey.ask'), {
        confirmButtonText: t('installConvey.confirm'),
        cancelButtonText: t('installConvey.cancel'),
        type: "warning"
      }).then(() => {
        selfActivityInstallationRequest(activityInstallationSelfRO, row);
      }).catch(() => {
        ElMessage({
          type: "info",
          message: t('installConvey.cancelDelete')
        });
      });
    };
    const selfActivityInstallationRequest = async (activityInstallationSelfRO, row) => {
      proxy.$api.scheduleRequest.selfActivityInstallation(activityInstallationSelfRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202267") {
            installationData.data.map(installation => {
              if (installation.installationId === row.installationId) {
                Object.assign(installation, res.result.data);
              }
              return installation;
            });
            installationData.data.filt;
            debugger;
            row = res.result.data;
            ElNotification({
              title: "success",
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: t('installConvey.failure'),
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: t('installConvey.failure'),
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: "error",
          message: error,
          type: "error",
          duration: 3000
        });
      });
    };
    const deliveredLogisticsRequest = async row => {
      proxy.$api.scheduleRequest.deliveredLogistics(row.logisticsId).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202264") {
            ElNotification({
              title: "success",
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "success",
              duration: 3000
            });
            row.expressStateName = res.result.data.expressStateName;
            row.expressStateId = res.result.data.expressStateListData;
          } else {
            ElNotification({
              title: t('installConvey.failure'),
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: t('installConvey.failure'),
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: "error",
          message: error,
          type: "error",
          duration: 3000
        });
      });
    };
    const batchDeliveredLogisticsRequest = async rows => {
      let logisticsIds = [];
      for (let convery of rows) {
        logisticsIds.push(convery.logisticsId);
      }
      proxy.$api.scheduleRequest.batchDeliveredLogistics(logisticsIds).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202264") {
            ElNotification({
              title: "success",
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "success",
              duration: 3000
            });
            for (let convery of rows) {
              convery.expressStateName = "已签收";
              convery.expressStateId = "3";
            }
            const $table = conveyTable.value;
            if ($table) {
              $table.clearCheckboxRow();
            }
            deliveredControl.isOpenBatch = false;
          } else {
            ElNotification({
              title: t('installConvey.failure'),
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: t('installConvey.failure'),
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: "error",
          message: error,
          type: "error",
          duration: 3000
        });
      });
    };
    const checkboxConfig = reactive({
      visibleMethod({
        row
      }) {
        return row.logisticsTypeId != 1 && row.expressStateName != "已签收";
      }
    });
    const rowClassName = row => {
      if (row.row.installationConfirmStatusId === 1) {
        return "borderLeftRed";
      } else if (row.row.installationConfirmStatusId === 2) {
        return "borderLeftYellow";
      } else {
        return "borderLeftGreen";
      }
    };
    const cityChange = value => {
      if (storeData.storePageRO.provinceCity) {
        storeData.storePageRO.provinceCity = value[value.length - 1];
      }
      getStoreByPageRequest(1, storeData.paginationData.pageSize, storeData.storePageRO);
    };
    return {
      installConveyData,
      importMessage,
      tokenData,
      activeName,
      permission,
      router,
      installQuery,
      activityData,
      installTable,
      installPaginationData,
      uploadInstallPane,
      uploadInstallPopoverVisible,
      uploadInstallData,
      installLoading,
      cityData,
      storeTypeData,
      vendorData,
      vendorTypeData,
      installationStatusData,
      getInstallationConfirmData,
      paginationSizeInstallChange,
      paginationCurrentInstallChange,
      openUploadInstallPopover,
      handleInstallExceed,
      handleInstallBeforeUpload,
      handleInstallOnSuccess,
      getDate,
      headerCellInstallClickEvent,
      searchInstallClick,
      copyStore,
      copyVendor,
      copyResult,
      installTableScroll,
      expandConfig,
      installUploadClick,
      goConveyPlan,
      activityId,
      vendorId,
      conveyQuery,
      conveyData,
      conveyTable,
      conveyPaginationData,
      uploadConverPane,
      uploadConverPopoverVisible,
      uploadConverData,
      conveyLoading,
      expressStateListData,
      logisticsTypeData,
      paginationSizeConverChange,
      paginationCurrentConverChange,
      openUploadConverPopover,
      handleConverExceed,
      handleConverBeforeUpload,
      handleConverOnSuccess,
      headerCellConverClickEvent,
      searchConverClick,
      openExportEvent,
      conveyTableScroll,
      deliveredLogistics,
      installationData,
      openInstallationEvent,
      batchDeliveredLogistics,
      deliveredControl,
      checkboxConfig,
      rowClassName,
      initExpressMap,
      onExpressInfoClose,
      expressInfoOpen,
      currentExpress,
      onExpressInfoOpen,
      map,
      _AMap,
      provinceDictData,
      cityChange,
      storeTypeInstallationData,
      storeTypeLogisticsData,
      selfActivityInstallation
    };
  }
};