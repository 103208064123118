import "core-js/modules/es.array.push.js";
import { defineComponent, reactive, ref, onMounted, onUnmounted, computed, getCurrentInstance } from "vue";
import { DownCircleOutlined, PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons-vue";
import SvgIcon from "../components/SvgIcon.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper/modules";
import useClipboard from "vue-clipboard3";
import permission from "@/util/permission";
import html2canvas from "html2canvas";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import QrcodeVue from "qrcode.vue";
import showConfirmModal from "@/util//showConfirmModal";
import { v4 as uuidv4 } from "uuid";
import { useI18n } from "vue-i18n";

import { ElNotification } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
export default defineComponent({
  name: "RepairManagement",
  components: {
    Swiper,
    SwiperSlide,
    SvgIcon,
    QrcodeVue,
    DownCircleOutlined
  },
  setup() {
    const {
      t,
      locale
    } = useI18n(); // 获取 locale 引用
    const {
      proxy
    } = getCurrentInstance();
    const {
      toClipboard
    } = useClipboard();
    const currentProtocol = window.location.protocol;
    const currentDomain = window.location.hostname;
    const currentPort = window.location.port;
    let currentUrl = currentProtocol + "//" + currentDomain;
    if (currentPort) {
      currentUrl += ":" + currentPort;
    }
    const jumpUrl = ref(currentUrl + "/repair-message");
    // const jumpUrl = ref("https://servicechannel.online/repair-message");
    // const jumpUrl = ref("http://192.168.60.248:8080/repair-message")
    // Loding开关
    const userLoading = ref(false);
    const wxCodeImage = ref(null);

    // 分页配置
    const paginationData = reactive({
      pageSizes: [25, 50, 100],
      currentPage: 1,
      totalSize: 0,
      pageSize: 25
    });
    const subscribeId = ref(null);
    const QRcodeBox = ref(null);
    const appointmentFormData = reactive({
      repairPerson: null,
      contactPerson: null,
      repairPersonContact: null,
      coordinatorContact: null,
      repairDate: null
    });
    const repairData = reactive([]);
    const repairDataCopy = reactive([]);
    const repairEnd = ref(null);
    const repairStar = ref(null);
    const headerCellClickEvent = ({
      column,
      triggerResizable,
      triggerSort,
      triggerFilter
    }) => {
      const $table = xTable.value;
      if ($table) {
        // 如果触发了列的其他功能按钮
        if (column.sortable && !(triggerResizable || triggerSort || triggerFilter)) {
          if (column.order === "desc") {
            $table.clearSort();
          } else if (column.order === "asc") {
            $table.sort(column.field, "desc");
          } else {
            $table.sort(column.field, "asc");
          }
        }
      }
    };
    const activeName = ref("first");
    var showPopover = null;
    const xTable = ref();
    const valuev2 = ref();
    const popoverVisible = ref(false);
    const QRcodeVisible = ref(false);
    const photoVisible = ref(false);
    const storeNameSelect = ref(null);
    const vendorNameSelect = ref(null);
    const QRcodeMsg = reactive({});
    const photosView = reactive([]);
    const appointmentDialogVisible = ref(false);
    const storeData = reactive([]);
    const vendorData = reactive([]);
    const vendorTypeVendorData = reactive([]);
    const assignOpenVisible = ref(false);
    const assignRepairEditForm = ref(null);
    const assignRepairEditFormData = reactive({
      repairId: null,
      storeName: null,
      locationName: null,
      repairerVendorId: null,
      repairerLeadName: null,
      repairerLeadMobile: null,
      repairerLeadEmail: null
    });
    const userOptions = reactive([]);
    const userData = reactive([]);

    //感叹号弹框
    const showAppointmentInfo = (className, row, rowIndex) => {
      if (showPopover != null) {
        var popover = document.getElementsByClassName(showPopover.className);
        showPopover.row.popoverVisible = false;
        showPopover = null;
      }
      row.popoverVisible = true;
      showPopover = {
        className: className,
        row: row
      };
    };
    const initials = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j"];
    const optionsv2 = Array.from({
      length: 10000
    }).map((_, idx) => ({
      value: `Option ${idx + 1}`,
      label: `${initials[idx % 10]}${idx}`
    }));
    const handleTouchStartOutside = event => {
      if (showPopover != null) {
        var popover = document.getElementsByClassName(showPopover.className);
        if (popover[0] && isVisible(popover[0]) && !popover[0].contains(event.target)) {
          // 点击发生在指定div外部
          console.log("点击发生在div外部");
          showPopover.row.popoverVisible = false;
          showPopover = null;
        }
      }
    };
    // 监听分页Select变化
    const paginationSizeChange = () => {
      let data = {
        endDate: repairEnd.value,
        startDate: repairStar.value,
        storeName: storeNameSelect.value,
        vendorName: vendorNameSelect.value
      };
      getRepair(data);
    };
    // 监听分页当前页变化
    const paginationCurrentChange = () => {
      let data = {
        endDate: repairEnd.value,
        startDate: repairStar.value,
        storeName: storeNameSelect.value,
        vendorName: vendorNameSelect.value
      };
      getRepair(data);
    };
    // 获取报修列表
    const getRepair = (data, add) => {
      let repairSelectRO;
      if (data) {
        repairSelectRO = {
          data: data,
          pageIndex: paginationData.currentPage,
          pageSize: paginationData.pageSize
        };
      } else {
        repairSelectRO = {
          data: {},
          pageIndex: paginationData.currentPage,
          pageSize: paginationData.pageSize
        };
      }
      proxy.$api.repairRequest.getRepair(repairSelectRO).then(res => {
        if (res.code === "200000") {
          console.log(res);
          if (res.result.dataCode === "202281") {
            if (res.result.data.pageList != null) {
              let Arr = res.result.data.pageList.map(item => ({
                ...item,
                locationDescription: item.storeFloor + "/" + item.category + "/" + item.region + "/" + item.location + "/" + item.locationSummary
              }));
              let uniqueArr = Arr.reduce((acc, current) => {
                // 检查当前活动名称是否已存在于累积数组中
                const x = acc.find(item => item.activityName === current.activityName);
                // 如果不存在，则将当前项目添加到累积数组中
                if (!x) {
                  return acc.concat([current]);
                } else {
                  return acc;
                }
              }, []);
              repairDataCopy.length = 0;
              repairDataCopy.push(...uniqueArr);
              if (add) {
                repairData.push(...res.result.data.pageList.map(item => ({
                  ...item,
                  locationDescription: item.storeFloor + "/" + item.category + "/" + item.region + "/" + item.location + "/" + item.locationSummary
                })));
              } else {
                repairData.length = 0;
                repairData.push(...res.result.data.pageList.map(item => ({
                  ...item,
                  locationDescription: item.storeFloor + "/" + item.category + "/" + item.region + "/" + item.location + "/" + item.locationSummary
                })));
              }
            } else {
              repairData.length = 0;
            }
            const $table = xTable.value;
            $table.reloadData(repairData);
            paginationData.totalSize = res.result.data.totalCount;
          } else {
            ElNotification({
              title: "error",
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "error",
            message: res.msg,
            type: "error",
            duration: 3000
          });
        }
        userLoading.value = false;
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          console.log(error);
        } else {
          ElNotification({
            title: "error",
            message: error,
            type: "error",
            duration: 3000
          });
        }
        userLoading.value = false;
      });
    };
    const getCurrentDateTime = () => {
      let date = new Date();
      let year = date.getFullYear();
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let day = ("0" + date.getDate()).slice(-2);
      let hours = ("0" + date.getHours()).slice(-2);
      let minutes = ("0" + date.getMinutes()).slice(-2);
      let seconds = ("0" + date.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };
    //时间戳转时间
    const getDate = data => {
      console.log("getDate");
      console.log(data);
      let date = new Date(data); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + "-";
      let M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      let D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      let h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      let m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      let s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    };
    const handleClick = () => {};
    //确认预约
    const confirmSubscribe = () => {
      let Time = getCurrentDateTime();
      // console.log(Time);
      appointmentDialogVisible.value = false;
      let repairRO = {
        repairId: subscribeId.value,
        repairTime: Time,
        repairerMobile: appointmentFormData.repairPersonContact,
        repairerName: appointmentFormData.repairPerson,
        storeStaffMobile: appointmentFormData.coordinatorContact,
        storeStaffName: appointmentFormData.contactPerson
      };
      proxy.$api.repairRequest.repairSchedule(repairRO).then(res => {
        if (res.code === "200000") {
          console.log(res);
          if (res.result.dataCode === "202282") {
            ElNotification({
              title: "success",
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "sucess",
              duration: 3000
            });
            let data = {
              endDate: repairEnd.value,
              startDate: repairStar.value,
              storeName: storeNameSelect.value,
              vendorName: vendorNameSelect.value
            };
            getRepair(data);
          } else {
            ElNotification({
              title: "error",
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "error",
            message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
            type: "error",
            duration: 3000
          });
        }
        userLoading.value = false;
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          console.log(error);
        } else {
          ElNotification({
            title: "error",
            message: error,
            type: "error",
            duration: 3000
          });
        }
        userLoading.value = false;
      });
    };
    //弹出二维码
    const openQrcode = row => {
      console.log(row);
      getRepairWxCode(row.repairId);
      // QRcodeVisible.value = true;
      QRcodeMsg.row = row;
      // console.log(jumpUrl.value + "?id=" + QRcodeMsg.row.repairId);
    };
    //点击照片显示轮播图
    const openPhotos = arr => {
      photoVisible.value = true;
      photosView.length = 0;
      photosView.push(...arr);
      console.log(photosView);
    };
    //点击查询
    const queryRepair = () => {
      let data = {
        endDate: repairEnd.value,
        startDate: repairStar.value,
        storeName: storeNameSelect.value,
        vendorName: vendorNameSelect.value
      };
      getRepair(data);
    };
    //获取所有店铺
    const getStore = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.storeRequest.getStoreAll(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "error",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    // 获取所有供应商
    const getVendor = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.vendorRequest.getVendorAll(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          ElNotification({
            title: "error",
            message: error,
            type: "error",
            duration: 3000
          });
          resolve(null);
        });
      });
    };
    const getVendorTypeVendor = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.vendorRequest.getVendorTypeVendor(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "error",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const getVendorUserRequest = async vendorId => {
      return new Promise((resolve, reject) => {
        proxy.$api.vendorRequest.getVendorUser(vendorId).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "error",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };

    // 预约信息复制
    const copyInfo = async row => {
      let text = "对接人：" + row.storeStaffName + "\n" + "对接人电话：" + row.storeStaffMobile + "\n" + "维修人：" + row.repairerName + "\n" + "维修人电话：" + row.repairerMobile + "\n" + "维修日期：" + getDate(row.repairTime);
      console.log(text);
      try {
        await toClipboard(text); //实现复制
        ElNotification({
          title: "success",
          message: t('repairManage.copiedToClipboard'),
          type: "success",
          duration: 3000
        });
      } catch (e) {
        console.error(e);
      }
    };

    //二维码复制
    const copyQrcode = () => {
      html2canvas(QRcodeBox.value, {
        useCORS: true,
        // 【重要】开启跨域配置
        allowTaint: true // 允许跨域图片
      }).then(canvas => {
        canvas.toBlob(blob => {
          const data = [new ClipboardItem({
            [blob.type]: blob
          })];
          navigator.clipboard.write(data).then(() => {
            ElNotification({
              title: "success",
              message: t('repairManage.copiedToClipboard'),
              type: "success",
              duration: 3000
            });
          }, () => {
            ElNotification({
              title: "error",
              message: t('repairManage.copyNotSupported'),
              type: "error",
              duration: 3000
            });
          });
        }, "image/png");
      });
    };
    const focusChange = () => {
      console.log(123);
    };
    const userTableScroll = ({
      type,
      scrollTop,
      scrollLeft,
      scrollHeight,
      scrollWidth,
      bodyWidth,
      bodyHeight,
      isX,
      isY,
      $event
    }) => {
      let mediaQuery = window.matchMedia("(max-width: 768px)");
      if (mediaQuery.matches) {
        if (scrollTop + bodyHeight >= scrollHeight - 20) {
          let totalCount = Math.ceil(paginationData.totalSize / paginationData.pageSize);
          console.log("-------------------" + paginationData.currentPage < totalCount);
          if (paginationData.currentPage < totalCount) {
            console.log("翻页");
            paginationData.currentPage++;
            let data = {
              cityName: Msg.insSelect.areaInput,
              searchKeyword: Msg.insValue,
              storeType: Msg.insSelect.storeType,
              vendorId: Msg.insSelect.vendorId,
              logisticsTypeId: Msg.insSelect.logisticsTypeId,
              state: Msg.insSelect.state
            };
            getLogisticsPageApi(data, paginationData.currentPage, paginationData.pageSize, "add");
          }
        }
      }
    };
    onMounted(async () => {
      if (!permission.havePermission("报修", "报修查看")) {
        ElNotification({
          title: "Failure",
          message: "No Permission",
          type: "error",
          duration: 3000
        });
        router.push({
          path: "/activity-browser"
        });
      }
      window.addEventListener("touchstart", handleTouchStartOutside);
      window.addEventListener("click", handleTouchStartOutside);
      let windowWidth = window.innerWidth;
      console.log(windowWidth);
      if (windowWidth <= 768) {
        paginationData.pageSize = 20;
      }
      getRepair();
      let store = await getStore();
      if (store == null) {
        return;
      }
      for (let x in store) {
        let Obj = {
          value: store[x].storeName,
          label: store[x].storeName
        };
        storeData.push(Obj);
      }
      let vendor = await getVendor();
      if (vendor == null) {
        return;
      }
      vendorData.push(...vendor);
      let vendorTypeVendorList = await getVendorTypeVendor();
      if (vendorTypeVendorList == null) {
        return;
      }
      vendorTypeVendorData.push(...vendorTypeVendorList.map(vendorTypeVendorVO => {
        return {
          label: vendorTypeVendorVO.vendorTypeNameZh,
          value: uuidv4(),
          disabled: true,
          children: vendorTypeVendorVO.vendors.map(vendor => ({
            value: vendor.vendorId,
            label: vendor.vendorName
          }))
        };
      }));
    });
    onUnmounted(() => {
      window.removeEventListener("touchstart", handleTouchStartOutside);
      window.removeEventListener("click", handleTouchStartOutside);
    });
    const isVisible = element => {
      const style = window.getComputedStyle(element);
      return style.display !== "none" && style.visibility !== "hidden" && style.opacity !== "0";
    };
    const getRepairWxCode = id => {
      let repairWxCodeRO = {
        id: id
      };
      proxy.$api.wxRequest.getRepairWxCode(repairWxCodeRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "200000") {
            wxCodeImage.value = res.result.data;
            QRcodeVisible.value = true;
          } else {
            ElNotification({
              title: "error",
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "error",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "错误",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    const rowClassName = row => {
      if (row.row.repairResultId === 4) {
        return "borderLeftGreen";
      } else {
        return "borderLeftRed";
      }
    };
    const cancelRepair = row => {
      showConfirmModal({
        title: "取消确认",
        content: "是否要取消这条维修记录？",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          let repairCancelRO = {
            repairId: row.repairId
          };
          cancelRepairRequest(repairCancelRO);
        }
      });
    };
    const cancelRepairRequest = repairCancelRO => {
      proxy.$api.repairRequest.cancelRepair(repairCancelRO).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "202288") {
            queryRepair();
            ElNotification({
              title: "Success",
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "success",
              duration: 3000
            });
          } else {
            ElNotification({
              title: "error",
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "error",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          resolve(null);
        } else {
          ElNotification({
            title: "error",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    const repairStoreLocationChange = async value => {
      if (value === 0) {} else {
        let userList = await getVendorUserRequest(value);
        userOptions.length = 0;
        filterUserOption.length = 0;
        if (Array.isArray(userList)) {
          userOptions.push(...userList.map(user => {
            return {
              value: user.lastName + user.firstName
            };
          }));
          filterUserOption.push(...userList.map(user => {
            return {
              value: user.lastName + user.firstName
            };
          }));
          userData.push(...userList.map(user => {
            return {
              value: user.lastName + user.firstName,
              mobile: user.mobile,
              email: user.email
            };
          }));
        }
      }
    };
    const handleSearch = value => {
      debugger;
      filterUserOption.length = 0;
      if (value) {
        filterUserOption.push(...userOptions.filter(option => option.value.toLowerCase().includes(value.toLowerCase())));
      } else {
        filterUserOption.push(...userOptions); // 重置选项
      }
    };

    const filterUserOption = reactive([]);
    // const filterUserOption = (input, option) => {
    //   return option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0;
    // };

    const selectUser = value => {
      let findUser = userData.filter(user => {
        return user.value === value;
      });
      if (Array.isArray(findUser)) {
        let user = findUser[0];
        assignRepairEditFormData.repairerLeadMobile = user.mobile;
        assignRepairEditFormData.repairerLeadEmail = user.email;
      }
    };
    const assignRepair = row => {
      assignRepairEditFormData.repairId = row.repairId;
      assignRepairEditFormData.storeName = row.storeName;
      if (row.storeLocationId == null) {
        assignRepairEditFormData.locationName = row.customStoreLocation;
      } else {
        assignRepairEditFormData.locationName = row.storeFloor + "_" + row.category + "_" + row.region + "_" + row.location + "_" + row.locationSummary;
        assignRepairEditFormData.repairerVendorId = null;
        assignRepairEditFormData.repairerLeadName = null;
        assignRepairEditFormData.repairerLeadMobile = null;
        assignRepairEditFormData.repairerLeadEmail = null;
      }
      assignOpenVisible.value = true;
    };
    const onAssignRepairCancel = () => {
      assignRepairEditForm.value.resetFields();
      assignOpenVisible.value = false;
    };
    const onAssignRepairSave = () => {
      assignRepairEditForm.value.validate().then(async () => {
        assignRepairRequest();
      }).catch(error => {
        console.log("error", error);
      });
    };
    const assignRepairRequest = () => {
      let assignRepairRO = {
        repairId: assignRepairEditFormData.repairId,
        repairerVendorId: assignRepairEditFormData.repairerVendorId,
        repairerLeadName: assignRepairEditFormData.repairerLeadName,
        repairerLeadMobile: assignRepairEditFormData.repairerLeadMobile,
        repairerLeadEmail: assignRepairEditFormData.repairerLeadEmail
      };
      proxy.$api.repairRequest.assignRepair(assignRepairRO).then(res => {
        if (res.code === "200000") {
          console.log(res);
          if (res.result.dataCode === "202290") {
            ElNotification({
              title: "success",
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "sucess",
              duration: 3000
            });
            queryRepair();
            assignRepairEditForm.value.resetFields();
            assignOpenVisible.value = false;
          } else {
            ElNotification({
              title: "error",
              message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
              type: "error",
              duration: 3000
            });
          }
        } else {
          ElNotification({
            title: "error",
            message: locale.value === "zh" ? res.result.promptMsg : res.result.dataMsg,
            type: "error",
            duration: 3000
          });
        }
      }).catch(error => {
        if (error.code == "TOKEN_MISSING") {
          console.log(error);
        } else {
          ElNotification({
            title: "error",
            message: error,
            type: "error",
            duration: 3000
          });
        }
      });
    };
    return {
      permission,
      paginationData,
      headerCellClickEvent,
      xTable,
      activeName,
      showAppointmentInfo,
      optionsv2,
      valuev2,
      popoverVisible,
      isVisible,
      appointmentDialogVisible,
      appointmentFormData,
      repairData,
      userLoading,
      subscribeId,
      QRcodeVisible,
      QRcodeMsg,
      photoVisible,
      photosView,
      modules: [Navigation, Pagination],
      repairStar,
      repairEnd,
      storeData,
      vendorData,
      storeNameSelect,
      vendorNameSelect,
      jumpUrl,
      paginationSizeChange,
      paginationCurrentChange,
      confirmSubscribe,
      openQrcode,
      handleClick,
      getDate,
      openPhotos,
      queryRepair,
      copyInfo,
      focusChange,
      userTableScroll,
      copyQrcode,
      QRcodeBox,
      wxCodeImage,
      getRepairWxCode,
      rowClassName,
      repairDataCopy,
      cancelRepair,
      assignOpenVisible,
      assignRepairEditForm,
      assignRepairEditFormData,
      vendorTypeVendorData,
      repairStoreLocationChange,
      userOptions,
      userData,
      filterUserOption,
      selectUser,
      assignRepair,
      onAssignRepairCancel,
      onAssignRepairSave,
      handleSearch
    };
  }
});